/*******************************
        User Overrides
*******************************/
.ui.selection.dropdown .menu > .item {
  border-top: none;
}

.ui.dropdown.selected,
.ui.dropdown .menu .selected.item {
  color: #d9d9d9;
}

.ui.search.dropdown > input.search {
  color: #d9d9d9;
  padding: 10px 8px;
  height: 100%;
  width: 99%;
}


.ui.search.dropdown > i.dropdown.icon {
  top: 1.5rem;
}

.ui.dropdown, .ui.dropdown.selection {
  padding: 10px 8px;
  color: #737373;
  background-color: #2D2D2D;
  width: 100%;
  border-radius: 3px;
  border: 2px solid #474747 !important;


  &:hover {
    background-color: #323232;
  }

  &:focus, &:active, &.active {
    background-color: #151515;
    border: 2px solid;
    border-color: @focusedFormBorderColor !important;
    border-radius: 3px 3px 0 0;

  }

  .menu {
    border-width: 2px;
    border-top-width: 0 !important;
    width: calc(100% + 4px) !important;
    border-color: @focusedFormBorderColor !important;
    left: -2px !important;
    background-color: #151515;
    border-radius: 0 0 3px 3px;
    margin: 0;
    .item:hover{
      background-color: #FFFFFF1A !important;
    }

    .selected{
      background-color: #1D73DD !important;

    }

  }

  .text {
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    color: #D9D9D9;
  }

  &.upward {
    &:active, &:focus {
      border-radius: 0 0 3px 3px;
    }
    .menu {
      border-radius: 3px 3px 0 0;
      border-bottom-width: 0;
      border-top-width: 2px !important;
    }
  }
}

.ui.multiple.selection.dropdown{
  a{
    font-size: 12px;
  }
}

.ui.loading.dropdown > i.icon {
  height: unset !important;
}