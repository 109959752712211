/*******************************
        User Overrides
*******************************/
.ui.popup {
  border: none;
  min-width: 120px;
}

.ui.popup.sidekick-tooltip {
  padding: 4px 6px;
  color: #2f2f2f;
  background-color: #e2e2e2;

  &::before {
    background-color: #e2e2e2;
    border: none;
  }
}

.ui.popup.source-provider-ellipsis-popup {
  padding: 8px 0;

  .source-provider-popup-button {
    padding: 4px 8px;

    &:hover {
      color: white;
      background: rgba(217, 217, 217, 0.1);
      cursor: pointer;
    }
  }
}

.ui.popup {
  &:before {
    box-shadow: 1px 1px 0px 0px #232323 !important;
  }

  .popup-header {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;

    color: #FFFFFF;

    margin-bottom: 4px;
  }

  .popup-content {
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;

    color: #FFFFFF;
  }
}

.tooltip {
  text-align: center;

  .content {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;

    color: #FFFFFF;
  }

}