/*******************************
        User Overrides
*******************************/

//
.accordion.ui.source-accordion {
  // color: #D9D9D9;
  // color: red;

  .title {
    color: #d9d9d9;
  }
}
