/*******************************
         Site Overrides
*******************************/

.ui.button.medium-button { // sidekick-medium
  padding: 4px 12px;
}

.ui.button.thin-button { // sidekick-thin
  padding: 0 12px;
}

.ui.button.small-button{
  padding: 4px 8px;
}

.ui.button.wide-button{
  padding: 6px 12px;
}

.ui.button.secondary{
 background-color: #D9D9D90D !important;
  color: @textColor !important;

  &:hover{
    background-color: #595959 !important;
    color: #FFFFFF !important;
  }

}

//.ui.button {
//  background-color: #1D73DD !important;
//  padding: 6px 12px !important;
//  height: 32px;
//
//  &:hover {
//    background: #2A84F1 !important;
//
//  }
//}