/*******************************
         Site Overrides
*******************************/

.settings-table {
  .multi-info-cell {
    display: flex;
    gap: 15px;
    align-items: center;

    .user-icon {
      border-radius: 50%;
      color: black !important;

      &.owner {
        background-color: limegreen !important;
      }

      &.billing {
        background-color: whitesmoke !important;
      }

      &.status {
        background-color: #1d76e1 !important;
      }
    }
  }

  .name-avatar {
    padding: 5px;
    width: 24px;
    height: 24px;
    background-color: #D9D9D9;
    border-radius: 50%;
    color: #232323;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  tr:hover {
    box-shadow: 1px 2px 10px 0px #090a0c8f;
  }

  tr, tr div {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: #d9d9d9;
  }

  thead * {
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: -0.01em;
    text-align: left;
    border-bottom: 1px solid gray;

    &:hover {
      box-shadow: unset;
    }

    background: unset !important;
  }

  background: unset !important;
}