/*******************************
         Site Overrides
*******************************/

.ui.inverted.form input:focus {
  border-color: @inputFocusBorderColor;
}

.ui.form{
  .field {
    margin-bottom: 24px;
    input{
      border: 2px solid #474747;
      border-radius: 3px;
      padding: 10px 8px;
      font-size: 14px;
      line-height: 20px;
      font-weight: 400;
      color: #D9D9D9;
      &:hover{
        background-color: #323232;
      }

      &:focus{
        background-color: #151515;
        color:inherit;
      }
    }
  }
}

