/*******************************
         Site Overrides
*******************************/

.ui.input,
.ui.small.input {
  input,
  i {
    font-size: 11px;
  }
}

.field > label {
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 12px !important;
  line-height: 16px !important;
  letter-spacing: -0.01em !important;
  color: rgba(255, 255, 255, 0.5) !important;
}

.ui.input{
  width: 100%;
  input{
    border: 2px solid #474747;
    border-radius: 3px;
    padding: 10px 8px;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    color: #D9D9D9;
    &:hover{
      background-color: #323232;
    }

    &:focus{
      background-color: #151515;
      color:inherit;
    }
  }
}
