/*******************************
         Site Overrides
*******************************/
div,
p,
span {
  font-size: @small;
  line-height: @lineHeight;
}

//div[role=listbox]{
//  padding: 5px;
//  background-color: @inputBackground;
//
//  &.active{
//    background-color: #232323;
//    border-color: @focusedFormBorderColor !important;
//    border-bottom-color: unset !important;
//    border-style: solid;
//    border-width: 1px;
//  }
//  .menu{
//    border-radius: 0 !important;
//    border-top-width: 0 !important;
//    width: calc(100% + 2px);
//    border-color: @focusedFormBorderColor !important;
//    left: -1px !important;
//
//  }
//}

.button{
  background-color: @primaryColor !important;

  &:hover{
    background-color: #2A84F1 !important;
    color:#FFFFFF !important;
  }
}

span.anchor-like{
  color:@linkColor;

  &:hover{
    color:@linkHoverColor;
    cursor: pointer;
    text-decoration: underline;
  }
}

.button-step-info {
  height: fit-content;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}


.popup-menu{
  padding: unset !important;
  padding-top: 8px!important;
  padding-bottom: 12px !important;
  .popup-menu-item{
    cursor: pointer;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;

    color: #D9D9D9;

    padding: 6px 12px;

    &:hover {
      background-color: rgba(255, 255, 255, 0.1);
      color: #ffffff;
    }
  }
}

.interactive-icon {
  margin-left: auto;
  padding: 5px;
  height: 32px !important;
  width: 32px !important;
  display: flex !important;
  justify-content: center;
  align-items: center;
  margin-right: 0 !important;
  border-radius: 3px;
  &:hover {
    background-color: #d9d9d90d;
  }

  &.active {
    background-color: #d9d9d933;
  }
}

.events-container {
  width: 50%;
  height: 100%;
  display: flex;
  overflow-y: scroll;
  flex-direction: column;
  border-right: 1px solid #323232;

  .compare-events-section {
    table {
      thead {
        height: 0;
      }
    }
  }

  .header-group {
    display: flex;
    align-items: center;
    gap: 12px;
    color: #d9d9d9;
    padding: 4px;
    .header {
      font-style: normal;
      font-weight: 700;
      font-size: 11px;
      line-height: 16px;
      letter-spacing: -0.01em;
      text-transform: uppercase;
    }
    .event-count-label {
      font-style: normal;
      font-weight: 400;
      font-size: 11px;
      line-height: 16px;

      color: rgba(255, 255, 255, 0.7);
    }

    .icon-tp-events-trash {
      font-size: 17px;
    }
    .predefined-tps-dropdown {
      margin-left: auto !important;
      z-index: 1000 !important;
      width: 240px !important;
      padding-top: 0 !important;
      padding-bottom: 0 !important;
      display: flex !important;
      align-items: center !important;
      border-radius: 3px !important;
    }

    .compare-button {
      margin-left: auto;
    }

    .event-list-search-container {
      width: 240px;
      margin-left: auto;
      .search-box {
        margin: 0;
      }
    }
  }

}

.event-details-container {
  width: 50%;
  height: 100%;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  position: relative;

  .event-details-action-group-container {
    width: 100%;
    height: 27px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    border-bottom: 1px solid #323232;

    .ui.buttons {
      .ui.button {
        background-color: #404040 !important;
        border-radius: 0px !important;
        font-family: Inter;
        display: flex;
        align-items: center;
        height: 100%;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        color: rgba(255, 255, 255, 0.7);

        &.selected-btn {
          background-color: #2d2d2d !important;
          color: #ffffff;
        }

        &:focus {
          background-color: #2d2d2d !important;
          color: #ffffff;
        }

        &:hover {
          background-color: #2d2d2d !important;
          color: #ffffff;
        }
      }
    }
  }

  .event-details-group-container {
    height: calc(100% - 40px); //40px Event Filter, Clear Btn and Display EventInfo Row Height
    padding: 4px 12px;
    overflow-y: auto;
  }

  .not-selected-event-details-container {
    display: flex;
    height: 100%;
    flex-direction: row;
    justify-content: flex-start;

    .left-part {
      width: 33%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 20px;
    }

    .center-part {
      width: 33%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .first-line {
        color: rgba(212, 212, 212, 0.4);
      }

      .second-line {
        font-family: Inter;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        color: #d4d4d4;
      }

      .third-line {
        font-family: Inter;
        font-style: normal;
        font-weight: normal;
        font-size: 11px;
        line-height: 16px;
        color: rgba(212, 212, 212, 0.76);
      }
    }

    .right-part {
      display: flex;
      width: 33%;
      justify-content: center;
      align-items: center;
    }
  }
}

