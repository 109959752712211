/*******************************
         Site Overrides
*******************************/
.ui.modal {
  color: #d9d9d9;

  .ui.positive.button {
    background-color: #1d76e1;
    color: #ffffff;
  }

  .ui.negative.button {
    background-color: transparent;
    color: #d9d9d9;
  }
}


.ui.dimmer {
  background-color: rgba(35, 35, 35, 0.64);
}

.ui.modal.confirm-modal{
  padding: 24px;
  width: 600px;
  .header{
    display: flex;
    gap: 12px;
    align-items: center;

    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: -0.014em;
    color: #D9D9D9;

    i{
      color: #FFAB00;
    }
  }

  .content, .content div{

    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.014em;
    color: #CCCCCC;
  }

  .content{
    .do-you-want-to-proceed{
      div:last-of-type{
        margin-top: 24px;
      }
    }
  }

}
